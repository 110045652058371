import { Button } from 'primereact/button';
import { ListBox } from 'primereact/listbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Fieldset } from 'primereact/fieldset';
import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useToast } from '../../Toast';
const SavedFilter = ({ filters, setFilters, selectedFilters, setSelectedFilters, savedFilters, setSavedFilters }) => {
    const { showError } = useToast();
    const menu = useRef(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [filterToBeRemoved, setFilterToBeRemoved] = useState(null);
    const [name, setName] = useState('');
    const filteTemplate = (option) => {
        return (React.createElement("div", { className: "filter-item" },
            React.createElement("i", { className: "pi pi-star px-1" }),
            React.createElement("div", null, option.name),
            React.createElement("i", { style: { marginLeft: 'auto' }, onClick: (e) => {
                    setFilterToBeRemoved(option);
                    setShowRemoveDialog(true);
                    e.stopPropagation();
                }, className: "pi pi-trash px-1" })));
    };
    const deleteFilter = () => {
        let temp = savedFilters.filter(d => d.name !== (filterToBeRemoved === null || filterToBeRemoved === void 0 ? void 0 : filterToBeRemoved.name));
        setSavedFilters(temp);
        setShowRemoveDialog(false);
    };
    const showSaveFilter = () => {
        if (filters && Object.keys(filters).length > 0) {
            setShowDialog(true);
        }
    };
    const saveFilter = () => {
        if (savedFilters.find(x => x.name === name)) {
            showError("Save search", "name already exists!!");
            return;
        }
        const filter = {
            name,
            filters,
            selected: true
        };
        setSavedFilters([...savedFilters.map(x => (Object.assign(Object.assign({}, x), { selected: false }))), filter]);
        setSelectedFilters(filter);
        setShowDialog(false);
    };
    const selectSavedFilter = (val) => {
        var _a;
        // val.selected = true;
        // const selfil = savedFilters.find(x => x === val);
        setSavedFilters([...savedFilters.map(x => (Object.assign(Object.assign({}, x), { selected: x === val })))]);
        setFilters(val.filters);
        setSelectedFilters(val);
        (_a = menu.current) === null || _a === void 0 ? void 0 : _a.hide();
    };
    useEffect(() => {
        if (savedFilters && savedFilters.length > 0) {
            const sel = savedFilters.find(x => x.selected);
            if (sel) {
                // sel.selected = true;
                setFilters(sel.filters);
                setSelectedFilters(sel);
            }
        }
    }, [savedFilters]);
    const renderFooter = () => {
        return (React.createElement("div", null,
            React.createElement(Button, { label: "Cancel", icon: "pi pi-times", onClick: () => setShowDialog(false), className: "p-button-text" }),
            React.createElement(Button, { label: "Ok", icon: "pi pi-check", onClick: () => saveFilter(), autoFocus: true })));
    };
    const renderRemoveFooter = () => {
        return (React.createElement("div", null,
            React.createElement(Button, { label: "No", icon: "pi pi-times", onClick: () => setShowRemoveDialog(false), className: "p-button-text" }),
            React.createElement(Button, { label: "Yes", icon: "pi pi-check", onClick: () => deleteFilter(), autoFocus: true })));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { header: "Search name", visible: showDialog, onHide: () => setShowDialog(false), breakpoints: { '960px': '75vw' }, style: { width: '40vw', alignItem: 'center' }, footer: renderFooter() },
            React.createElement(InputText, { value: name, onChange: (e) => setName(e.target.value) })),
        React.createElement(Dialog, { header: "Are you sure to delete?", visible: showRemoveDialog, onHide: () => setShowRemoveDialog(false), breakpoints: { '960px': '75vw' }, style: { width: '40vw', alignItem: 'center' }, footer: renderRemoveFooter() }),
        React.createElement("div", { className: 'saved-filter-control p-splitbutton p-button-outlined mr-2 mb-2' },
            React.createElement(Button, { className: "p-button p-component p-button-sm p-splitbutton-defaultbutton", "aria-label": "Filter", onClick: showSaveFilter },
                React.createElement("i", { className: classNames("pi px-1", { 'pi-star-fill': !!selectedFilters, 'pi-star': !selectedFilters }) })),
            React.createElement(Button, { className: "p-button p-component p-splitbutton-menubutton p-button-icon-only", "aria-label": "Filter", onClick: (event) => { var _a; return (_a = menu.current) === null || _a === void 0 ? void 0 : _a.toggle(event); } },
                React.createElement("i", { className: "pi pi-chevron-down px-1" })),
            React.createElement(OverlayPanel, { ref: menu, dismissable: true, className: "overlaypanel-filter" },
                React.createElement("div", null,
                    React.createElement(Button, { label: "Save this search as", className: "p-button-link" })),
                React.createElement(Fieldset, { className: 'mt-1', legend: React.createElement(React.Fragment, null,
                        "My Searches",
                        React.createElement("i", { className: "pi pi-star px-2" })) },
                    React.createElement(ListBox, { options: savedFilters, value: selectedFilters, onChange: (e) => selectSavedFilter(e.value), itemTemplate: filteTemplate, filterBy: "name", style: { border: 'none', width: '15rem' }, virtualScrollerOptions: { itemSize: 38 }, listStyle: { height: '200px', maxHeight: '250px' }, filter: true, filterPlaceholder: "Search in filters" }))))));
};
export default SavedFilter;
