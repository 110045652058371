const tags = {
    AUTH_OTP: 'AuthOTP',
    SETTINGS: 'Settings',
    PROJECTS: 'Projects',
    PROJECTS_ACTIVE: 'ProjectsActive',
    COST_CATEGORIES: 'CostCategories',
    COST_CODES: 'CostCodes',
    PROJECT_TYPES: 'ProjectTypes',
    STATES: 'States',
    CITIES: 'Cities',
    PROJECT_STATUS: 'ProjectStatus',
    VENDOR_TYPE: 'VendorType',
    VENDOR_GROUP: 'VendorGroup',
    VENDOR: 'Vendor',
    AP_TERM: 'APTerm',
    INVENTORY_ITEM: 'InventoryItem',
    UOM: 'UOM',
    INVENTORY_ITEMTYPE: 'InventoryItemType',
    INVENTORY_UOMTYPE: 'InventoryUOMType',
    INVENTORY_MFGR: 'InventoryManufacturer',
    WAREHOUSE: 'Warehouse',
    PROJECT_BLOCK: 'Block',
    PROJECT_FLOOR: 'Floor',
    PROJECT_UNIT: 'Unit',
    PAYMENT_TERM: 'PaymentTerm',
    PRICE_CHANGE: 'PriceChange',
    STATUS_CHANGE: 'StatusChange',
    AVAILABILITY: 'Availability',
    TRANSACTION: 'Transaction',
    TRANSACTION_ITEM: 'TransactionItem',
    PROJECT_PURCHASE_INQUIRY: "ProjectPurchaseInquiry",
    CUSTOMER: "Customer",
    PURCHASE_TEMPLATE: "PurchaseTemplate",
    PURCHASE_TEMPLATE_ITEM: "PurchaseTemplateItem",
    PURCHASE_ORDER: "PurchaseOrder",
    PURCHASE_ORDER_ITEM: "PurchaseOrderItem",
    PURCHASE_INVOICE: "PurchaseInvoice",
    PURCHASE_INVOICE_ITEM: "PurchaseInvoiceItem",
    VENDOR_PAYMENT: "VendorPayment",
    CONTRACTOR_PAYMENT: "ContractorPayment",
    VENDOR_PAYMENT_ITEM: "VendorPaymentItem",
    MODE_OF_PAYMENT: "ModeOfPayment",
    GRN_NUMBER: "GRNNumber",
    INVOICE_ITEM: "InvoiceItem",
    CONTRACT: "Contract",
    CONTRACT_INVOICE: "ContractInvoice",
    CONTRACT_TASK: "ContractTask",
    CONTRACT_STAGE: "ContractStage",
    ACTIVE_VENDOR: "ActiveVendor",
    ACTIVE_CONTRACTOR: "ActiveContractor",
    PURCHASE_ORDER_FOR_INVOICE: "PurchaseOrderForInvoice",
    PROJECT_TASK: "ProjectTask",
    INVOICE_FOR_VENDOR: "InvoiceForVendor",
    INVOICE_FOR_CONTRACTOR: "InvoiceForContractor",
    PURCHASE_TEMPLATE_ITEMS_FOR_TEMPLATE_ID: "PurchaseTemplateItemsForID",
    MATERIAL_RECEIVED_ITEMS_FOR_PO: "MaterialReceivedItemsForPO",
    INVOICE_ITEMS_FOR_GRN: "InvoiceItemsForGRN",
    STAGES_FOR_PROJECT: "StagesForProject",
    ACTIVE_CUSTOMERS: "ActiveCustomers",
    UNITS_FOR_PROJECT: "UnitsForProject",
    SALE_BOOKING: "SaleBooking",
    SALE_AGREEMENT: "SaleAgreement",
    SALE_AGREEMENT_ITEM: "SaleAgreementItem",
    ACTIVE_BOOKINGS: "ActiveBookings",
    INVENTORY_ITEM_SUB_TYPE: "InventoryItemSubType",
    ITEMS_FOR_ITEM_TYPE: "ItemsForItemType",
    UOM_FOR_ITEM_TYPE: "UOMForItemType",
    MANUFACTURERS_FOR_ITEM_TYPE: "ManufacturersForItemType",
    VENDOR_INVOICE: "VendorInvoice",
    USERS: "User",
    FRACTIONAL_MATERIAL_PACK: "FractionalMaterialPack",
    FRACTIONAL_ROD_PACK: "FractionalRodPack",
    WHOLE_ROD_PACK: "WholeRodPack",
    BEAM: "Beam",
    COLUMN_BODY: "ColumnBody",
    COLUMN_FOOTING: "ColumnFooting",
    CUPBOARD: "Cupboard",
    GENERIC_TANK: "GenericTank",
    KITCHEN_STAGE: "KitchenStage",
    LIFT_FOOTING: "LiftFooting",
    LINTEL_BEAM: "LintelBeam",
    PILE: "Pile",
    PILE_CAP: "PileCap",
    SLAB: "Slab",
    STAIRCASE_SLAB: "StaircaseSlab",
    CLIENTS: "Clients",
    COMPANIES: "Companies",
    STAFFS: "Staffs",
};
export default tags;
