import React from 'react';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { PAGE_SIZE } from '@igblsln/store';
import { Dropdown } from 'primereact/dropdown';
const template = {
    layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
    PrevPageLink: (options) => {
        return (React.createElement("button", { type: "button", className: classNames(options.className, 'border-round'), onClick: options.onClick, disabled: options.disabled },
            React.createElement("span", { className: "p-3" }, '<'),
            React.createElement(Ripple, null)));
    },
    NextPageLink: (options) => {
        return (React.createElement("button", { type: "button", className: classNames(options.className, 'border-round'), onClick: options.onClick, disabled: options.disabled },
            React.createElement("span", { className: "p-3" }, '>'),
            React.createElement(Ripple, null)));
    },
    PageLinks: (options) => {
        if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
            const className = classNames(options.className, { 'p-disabled': true });
            return (React.createElement("span", { className: className, style: { userSelect: 'none' } }, "..."));
        }
        return (React.createElement("button", { type: "button", className: options.className, onClick: options.onClick },
            options.page + 1,
            React.createElement(Ripple, null)));
    },
    RowsPerPageDropdown: (options) => {
        const dropdownOptions = [
            { label: PAGE_SIZE, value: PAGE_SIZE },
            { label: PAGE_SIZE + 5, value: PAGE_SIZE + 5 },
            { label: PAGE_SIZE + 10, value: PAGE_SIZE + 10 },
        ];
        return React.createElement(React.Fragment, null,
            " Rows Per Page ",
            React.createElement(Dropdown, { value: options.value, options: dropdownOptions, onChange: options.onChange }),
            ";");
    },
    CurrentPageReport: (options) => {
        return (React.createElement("span", { style: { color: 'var(--text-color)', userSelect: 'none', marginLeft: 40, textAlign: 'center' } },
            "Showing ",
            options.first,
            " - ",
            options.last,
            " of ",
            options.totalRecords));
    }
};
export default template;
