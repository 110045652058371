import { configureStore, combineReducers } from '@reduxjs/toolkit';
import authSlice from '../app/auth/authSlice';
import settingSlice from '../app/setting/settingSlice';
import { api } from '../service/baseApi';
import { api as authApi } from '../service/authApi';
import appSlice from '../app/appSlice';
// Add a dictionary to keep track of the registered async reducers
const asyncReducers = {};
// Define the Reducers that will always be present in the application
const staticReducers = {
    [api.reducerPath]: api.reducer,
    [authApi.reducerPath]: authApi.reducer,
    app: appSlice,
    auth: authSlice,
    settings: settingSlice
};
// Configure the store
export default function createStore(initialState) {
    const store = configureStore({
        reducer: staticReducers,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, authApi.middleware),
    });
    // Create an inject reducer function
    // This function adds the async reducer, and creates a new combined reducer
    const injectReducer = (key, asyncReducer) => {
        asyncReducers[key] = asyncReducer;
        store.replaceReducer(createReducer(asyncReducers));
    };
    // Return the modified store
    return Object.assign(Object.assign({}, store), { injectReducer });
}
function createReducer(asyncReducers) {
    return combineReducers(Object.assign(Object.assign({}, staticReducers), asyncReducers));
}
