import React, { useState } from 'react';
import { Outlet } from "react-router-dom";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import SideNav from '../SideNav';
import PanelMenu from '../PanelMenu';
import Loader from '../Loader';
import { Sidebar } from 'primereact/sidebar';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { currentPaymentMenu, useAppSelector } from '@igblsln/store';
const ModuleLayout = ({ navItems, panelMenu = false }) => {
    const paymentMenu = useAppSelector(currentPaymentMenu);
    let customNavItems = [...navItems];
    if (panelMenu) {
        customNavItems = navItems.map(item => {
            if (item.id === paymentMenu) {
                return Object.assign(Object.assign({}, item), { expanded: true });
            }
            return item;
        });
    }
    const [visibleLeftPane, setVisibleLeftPane] = useState(false);
    return (React.createElement(Splitter, { style: { height: 'calc(100vh - 58px)' } },
        React.createElement(ScrollPanel, { className: "block lg:hidden left-scroll-panel" },
            React.createElement(Button, { icon: "pi pi-angle-right", className: "p-button-rounded p-button-outlined left-pane-expand-button", "aria-label": "Expand", onClick: () => setVisibleLeftPane(!visibleLeftPane) }),
            React.createElement(Sidebar, { visible: visibleLeftPane, onHide: () => setVisibleLeftPane(false), style: { top: 55, width: '17rem' } }, panelMenu ?
                React.createElement(PanelMenu, { items: customNavItems, className: "side-nav-static-height" }) :
                React.createElement(SideNav, { items: customNavItems, className: "side-nav-static-height" }))),
        React.createElement(SplitterPanel, { size: 20, minSize: 20, className: "hidden lg:block" }, panelMenu ?
            React.createElement(PanelMenu, { items: customNavItems }) :
            React.createElement(SideNav, { items: customNavItems })),
        React.createElement(SplitterPanel, { size: 80, minSize: 60 },
            React.createElement("div", { className: 'card body-static-height' },
                React.createElement(React.Suspense, { fallback: React.createElement(Loader, null) },
                    React.createElement(Outlet, null))))));
};
export default ModuleLayout;
