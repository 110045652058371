import { api } from '../service';
import { tags } from '../constants';
const API_PATH = '/transaction/purchase/';
export const purchaseOrderApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        purchaseOrderForInvoice: build.query({
            query: ({ vendor, project }) => {
                return {
                    url: `${API_PATH}order/?vendor=${vendor}&project=${project}`,
                };
            },
            transformResponse: (baseQueryReturnValue, meta, arg) => {
                baseQueryReturnValue = baseQueryReturnValue.map(d => {
                    return Object.assign(Object.assign({}, d), { key: d.key.toString() });
                });
                return baseQueryReturnValue;
            },
            providesTags: (result) => result ? [
                ...result.map(({ id }) => ({ type: tags.PURCHASE_ORDER_FOR_INVOICE, id })),
                { type: tags.PURCHASE_ORDER_FOR_INVOICE, id: 'LIST' },
            ] : [{ type: tags.PURCHASE_ORDER_FOR_INVOICE, id: 'LIST' }],
        }),
        purchaseTemplateItemsForId: build.query({
            query: ({ id }) => {
                return {
                    url: `/transaction/purchase/template/items/?p_template_id=${id}`,
                };
            },
            providesTags: (result) => result ? [
                ...result.map(({ key }) => ({ type: tags.PURCHASE_TEMPLATE_ITEMS_FOR_TEMPLATE_ID, key })),
                { type: tags.PURCHASE_TEMPLATE_ITEMS_FOR_TEMPLATE_ID, id: 'LIST' },
            ] : [{ type: tags.PURCHASE_TEMPLATE_ITEMS_FOR_TEMPLATE_ID, id: 'LIST' }],
        }),
        materialReceivedItemsForPO: build.query({
            query: ({ id }) => {
                return {
                    url: `/transaction/purchase/order/items/?p_order_id=${id}`,
                };
            },
            providesTags: (result) => result ? [
                ...result.map(({ key }) => ({ type: tags.MATERIAL_RECEIVED_ITEMS_FOR_PO, key })),
                { type: tags.MATERIAL_RECEIVED_ITEMS_FOR_PO, id: 'LIST' },
            ] : [{ type: tags.MATERIAL_RECEIVED_ITEMS_FOR_PO, id: 'LIST' }],
        }),
        invoiceItemsForGRN: build.query({
            query: ({ id }) => {
                return {
                    url: `/transaction/goods/receipts/item?grn_key=${id}`,
                };
            },
            providesTags: (result) => result ? [
                ...result.map(({ key }) => ({ type: tags.INVOICE_ITEMS_FOR_GRN, key })),
                { type: tags.INVOICE_ITEMS_FOR_GRN, id: 'LIST' },
            ] : [{ type: tags.INVOICE_ITEMS_FOR_GRN, id: 'LIST' }],
        }),
    }),
});
export const { usePurchaseOrderForInvoiceQuery, usePurchaseTemplateItemsForIdQuery, useMaterialReceivedItemsForPOQuery, useInvoiceItemsForGRNQuery } = purchaseOrderApi;
