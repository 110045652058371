import { createSlice } from '@reduxjs/toolkit';
import { settingApi } from './settingApi';
const initialState = {
    setting: {
        settings: [],
        filters: {}
    },
    isDirty: false,
    isUpdateNeeded: false,
    isLoading: false,
    error: '',
};
const slice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        updateFilter: (state, action) => {
            state.setting.filters = action.payload;
        },
        updateGridSetting: (state, action) => {
            state.setting.filters[action.payload.gridId] = action.payload.value;
            state.isDirty = true;
        },
        saveFilterSetting: (state) => {
            if (state.isDirty) {
                const settings = state.setting.settings;
                const filterSetting = settings.find(x => x.option === 'filters');
                if (filterSetting) {
                    state.setting.settings = [...settings.filter(x => x !== filterSetting), Object.assign(Object.assign({}, filterSetting), { value: JSON.stringify(state.setting.filters) })];
                }
                else {
                    state.setting.settings = [...settings, { option: 'filters', value: JSON.stringify(state.setting.filters) }];
                }
                state.isDirty = false;
                state.isUpdateNeeded = true;
            }
        },
        changeUpdated: (state) => {
            state.isUpdateNeeded = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(settingApi.endpoints.settings.matchPending, (state, action) => {
            state.isLoading = true;
        })
            .addMatcher(settingApi.endpoints.settings.matchFulfilled, (state, action) => {
            state.setting.settings = action.payload;
            const filter = action.payload.find(x => x.option === 'filters');
            if (filter === null || filter === void 0 ? void 0 : filter.value) {
                state.setting.filters = JSON.parse(filter.value);
            }
        })
            .addMatcher(settingApi.endpoints.settings.matchRejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error.message || '';
        });
    },
});
export const { updateFilter, updateGridSetting, saveFilterSetting, changeUpdated } = slice.actions;
export default slice.reducer;
export const selectSettingIsLoading = (state) => state[slice.name].isLoading;
export const selectSettingIsDirty = (state) => state[slice.name].isDirty;
export const selectIsUpdateNeeded = (state) => state[slice.name].isUpdateNeeded;
export const selectFilterData = (state) => state[slice.name].setting.filters;
export const selectSettingsData = (state) => state[slice.name].setting.settings;
