import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
    name: 'app',
    initialState: { promptNavigate: false, paymentMenu: '' },
    reducers: {
        setPromptNavigate: (state, { payload: { promptNavigate } }) => {
            state.promptNavigate = promptNavigate;
        },
        setPaymentMenu: (state, action) => {
            state.paymentMenu = action.payload;
        }
    },
});
export const { setPromptNavigate, setPaymentMenu } = slice.actions;
export default slice.reducer;
export const selectPromptNavigate = (state) => state.app.promptNavigate;
export const currentPaymentMenu = (state) => state.app.paymentMenu;
