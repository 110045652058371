import { api } from '../service';
import { tags } from '../constants';
import { urlUtils } from '../util';
export const uomApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getAllUOMs: build.query({
            query: () => {
                return {
                    url: urlUtils('/inventory/item_uom/', `?without_pagination=1`),
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "ALL_UOMS", id })),
                { type: "ALL_UOMS", id: 'LIST' },
            ] : [{ type: "ALL_UOMS", id: 'LIST' }],
        }),
        getUOMsForItemType: build.query({
            query: (itemType) => {
                return {
                    url: `/inventory/item_uom/?item_type=${itemType}`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.UOM_FOR_ITEM_TYPE, id })),
                { type: tags.UOM_FOR_ITEM_TYPE, id: 'LIST' },
            ] : [{ type: tags.UOM_FOR_ITEM_TYPE, id: 'LIST' }],
        }),
        getUOMsForItem: build.query({
            query: (item) => {
                return {
                    url: `/inventory/item_uom/?item=${item}`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "UOM_FOR_ITEM", id })),
                { type: "UOM_FOR_ITEM", id: 'LIST' },
            ] : [{ type: "UOM_FOR_ITEM", id: 'LIST' }],
        }),
    }),
});
export const { useGetAllUOMsQuery, useGetUOMsForItemTypeQuery, useGetUOMsForItemQuery } = uomApi;
