import { api } from '../service';
import { tags } from '../constants';
import { urlUtils } from '../util';
const API_PATH = '/project/project/';
export const projectApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        activeProject: build.query({
            query: () => {
                return {
                    url: `${API_PATH}all/active`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.PROJECT, id })),
                { type: tags.PROJECT, id: 'LIST' },
            ] : [{ type: tags.PROJECT, id: 'LIST' }],
        }),
        getProject: build.query({
            query: (id) => `${API_PATH}${id}`,
            providesTags: (_Project, _err, id) => [{ type: tags.PROJECT, id }],
        }),
        listProjectsWithNoTasks: build.query({
            query: () => {
                return {
                    url: urlUtils('/project/non/task/projects'),
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.PROJECTS, id })),
                { type: tags.PROJECTS, id: 'PARTIAL-LIST' },
            ] : [{ type: tags.PROJECTS, id: 'PARTIAL-LIST' }],
        }),
        stagesForProject: build.query({
            query: ({ id }) => {
                return {
                    url: `/project/stage/?project=${id}&without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result.map(({ key }) => ({ type: tags.STAGES_FOR_PROJECT, key })),
                { type: tags.STAGES_FOR_PROJECT, id: 'LIST' },
            ] : [{ type: tags.STAGES_FOR_PROJECT, id: 'LIST' }],
        }),
        availableUnitsForProject: build.query({
            query: ({ id }) => {
                return {
                    url: `/project/unit/?project=${id}&without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ key }) => ({ type: tags.UNITS_FOR_PROJECT, key })),
                { type: tags.UNITS_FOR_PROJECT, id: 'LIST' },
            ] : [{ type: tags.UNITS_FOR_PROJECT, id: 'LIST' }],
        }),
        blocksForProject: build.query({
            query: ({ projectId }) => {
                return {
                    url: urlUtils('/project/block/', `?without_pagination=1&project=${projectId || ''}`),
                };
            },
            transformResponse: (baseQueryReturnValue, meta, arg) => {
                baseQueryReturnValue = baseQueryReturnValue.map((d, index) => {
                    return Object.assign(Object.assign({}, d), { proj_key: arg.projectId, sno: index + 1 });
                });
                return baseQueryReturnValue;
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "BlocksForProject", id })),
                { type: "BlocksForProject", id: 'LIST' },
            ] : [{ type: "BlocksForProject", id: 'LIST' }],
        }),
        floorsForProject: build.query({
            query: ({ projectId }) => {
                return {
                    url: urlUtils('/project/floor/', `?without_pagination=1&project=${projectId || ''}`),
                };
            },
            transformResponse: (baseQueryReturnValue, meta, arg) => {
                baseQueryReturnValue = baseQueryReturnValue === null || baseQueryReturnValue === void 0 ? void 0 : baseQueryReturnValue.map((d, index) => {
                    return Object.assign(Object.assign({}, d), { proj_key: arg.projectId, sno: index + 1 });
                });
                return baseQueryReturnValue;
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "FloorsForProject", id })),
                { type: "FloorsForProject", id: 'LIST' },
            ] : [{ type: "FloorsForProject", id: 'LIST' }],
        }),
        unitsForProject: build.query({
            query: ({ projectId }) => {
                return {
                    url: urlUtils('/project/unit/', `?without_pagination=1&project=${projectId || ''}`),
                };
            },
            transformResponse: (baseQueryReturnValue, meta, arg) => {
                baseQueryReturnValue = baseQueryReturnValue.map((d, index) => {
                    return Object.assign(Object.assign({}, d), { proj_key: arg.projectId, sno: index + 1 });
                });
                return baseQueryReturnValue;
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "UnitsForProject", id })),
                { type: "UnitsForProject", id: 'LIST' },
            ] : [{ type: "UnitsForProject", id: 'LIST' }],
        }),
    }),
});
export const { useGetProjectQuery, useActiveProjectQuery, useListProjectsWithNoTasksQuery, useStagesForProjectQuery, useAvailableUnitsForProjectQuery, useBlocksForProjectQuery, useFloorsForProjectQuery, useUnitsForProjectQuery } = projectApi;
