var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import { getCellStyle, getCellClassname, isCellEditable } from './utils';
import { useRovingCellRef } from './hooks';
const cellCopied = 'cellCopied';
const cellCopiedClassname = `rdg-cell-copied cellCopied`;
const cellDraggedOverClassname = `rdg-cell-dragged-over cellDraggedOver`;
function Cell(_a) {
    var { column, colSpan, isCellSelected, isCopied, isDraggedOver, row, dragHandle, onRowClick, onRowDoubleClick, onRowChange, selectCell } = _a, props = __rest(_a, ["column", "colSpan", "isCellSelected", "isCopied", "isDraggedOver", "row", "dragHandle", "onRowClick", "onRowDoubleClick", "onRowChange", "selectCell"]);
    const { ref, tabIndex, onFocus } = useRovingCellRef(isCellSelected);
    const { cellClass } = column;
    const className = getCellClassname(column, {
        [cellCopiedClassname]: isCopied,
        [cellDraggedOverClassname]: isDraggedOver
    }, typeof cellClass === 'function' ? cellClass(row) : cellClass);
    function selectCellWrapper(openEditor) {
        selectCell(row, column, openEditor);
    }
    function handleClick() {
        var _a;
        selectCellWrapper((_a = column.editorOptions) === null || _a === void 0 ? void 0 : _a.editOnClick);
        onRowClick === null || onRowClick === void 0 ? void 0 : onRowClick(row, column);
    }
    function handleContextMenu() {
        selectCellWrapper();
    }
    function handleDoubleClick() {
        selectCellWrapper(true);
        onRowDoubleClick === null || onRowDoubleClick === void 0 ? void 0 : onRowDoubleClick(row, column);
    }
    function handleRowChange(newRow) {
        onRowChange(column, newRow);
    }
    function handleFocus(event) {
        var _a;
        if (onFocus) {
            onFocus(event);
        }
        if ((_a = column.editorOptions) === null || _a === void 0 ? void 0 : _a.editOnFocus) {
            selectCellWrapper(true);
        }
    }
    return (React.createElement("div", Object.assign({ role: "gridcell", "aria-colindex": column.idx + 1, "aria-selected": isCellSelected, "aria-colspan": colSpan, "aria-readonly": !isCellEditable(column, row) || undefined, ref: ref, tabIndex: tabIndex, className: className, style: getCellStyle(column, colSpan), onClick: handleClick, onDoubleClick: handleDoubleClick, onContextMenu: handleContextMenu, onFocus: handleFocus }, props), !column.rowGroup && (React.createElement(React.Fragment, null,
        column.formatter({
            column,
            row,
            isCellSelected,
            onRowChange: handleRowChange
        }),
        dragHandle))));
}
export default memo(Cell);
