import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { setPromptNavigate } from '@igblsln/store';
const DirtyOnConfirm = ({ isDirty }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPromptNavigate({ promptNavigate: isDirty }));
    }, [isDirty]);
    return (React.createElement(React.Fragment, null));
};
export default DirtyOnConfirm;
