import { api } from '../service';
import { tags } from '../constants';
const API_PATH = '/geo/state/';
export const statesApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getStates: build.query({
            query: () => {
                return {
                    url: API_PATH,
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ state_key: id }) => ({ type: tags.STATES, id })),
                { type: tags.STATES, id: 'LIST' },
            ],
        }),
    }),
});
export const { useGetStatesQuery } = statesApi;
