var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Controller } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { Skeleton } from 'primereact/skeleton';
export const getFormErrorMessage = (errMsg) => {
    return errMsg && React.createElement("small", { className: "p-error" }, errMsg);
};
const Field = ({ name, id, label, control, errors, formItem, isLoading, leftSpan, rightSpan, convertValue, useExplicit, onChange, defaultValue, rules = {}, required, className, centerText }) => {
    var _a;
    const Item = formItem.component;
    const _b = formItem.componentProps || {}, { className: itemClassName, labelClassName } = _b, rest = __rest(_b, ["className", "labelClassName"]);
    return (React.createElement("div", { className: classNames('field grid grid-nogutter p-fluid', className) },
        isLoading && React.createElement(Skeleton, { height: "38px", className: "mb-2" }),
        !isLoading && React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames(labelClassName, `col-${leftSpan || 6}`, { 'p-error': !!required && errors[name], 'text-center': centerText }), style: { display: 'inline-table' } },
                React.createElement("span", { style: { display: 'table-cell', verticalAlign: 'middle' } },
                    label,
                    " ",
                    !!required && '*')),
            React.createElement("div", { id: id || name, className: `col-${rightSpan || 6} input-field` },
                React.createElement(Controller, { name: name, defaultValue: defaultValue || null, control: control, rules: Object.assign({ required: required === true ? `${label} is required` : required }, rules), render: ({ field, fieldState }) => (React.createElement(React.Fragment, null,
                        useExplicit && React.createElement(Item, Object.assign({ id: field.name, value: convertValue ? convertValue(field.value) : field.value, onChange: (e) => {
                                onChange && onChange(e);
                                field.onChange(convertValue ? convertValue(e.value, true) : e.value || e.target.value);
                            }, className: classNames(itemClassName, { 'p-invalid': !!fieldState.error }) }, rest)),
                        !useExplicit && React.createElement(Item, Object.assign({}, field, { className: classNames(itemClassName, { 'p-invalid': !!fieldState.error }) }, rest)))) }),
                getFormErrorMessage((_a = errors === null || errors === void 0 ? void 0 : errors[name]) === null || _a === void 0 ? void 0 : _a.message)))));
};
export default Field;
