var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, forwardRef } from 'react';
import clsx from 'clsx';
import './styles.scss';
import Cell from './Cell';
import { RowSelectionProvider, useLatestFunc } from './hooks';
import { getColSpan, getRowStyle } from './utils';
const rowClassname = `rdg-row dg-row`;
const rowSelectedClassname = `rdg-row-selected`;
function Row(_a, ref) {
    var { className, rowIdx, gridRowStart, height, selectedCellIdx, isRowSelected, copiedCellIdx, draggedOverCellIdx, lastFrozenColumnIndex, row, viewportColumns, selectedCellEditor, selectedCellDragHandle, onRowClick, onRowDoubleClick, rowClass, setDraggedOverRowIdx, onMouseEnter, onRowChange, selectCell } = _a, props = __rest(_a, ["className", "rowIdx", "gridRowStart", "height", "selectedCellIdx", "isRowSelected", "copiedCellIdx", "draggedOverCellIdx", "lastFrozenColumnIndex", "row", "viewportColumns", "selectedCellEditor", "selectedCellDragHandle", "onRowClick", "onRowDoubleClick", "rowClass", "setDraggedOverRowIdx", "onMouseEnter", "onRowChange", "selectCell"]);
    const handleRowChange = useLatestFunc((column, newRow) => {
        onRowChange(column, rowIdx, newRow);
    });
    function handleDragEnter(event) {
        setDraggedOverRowIdx === null || setDraggedOverRowIdx === void 0 ? void 0 : setDraggedOverRowIdx(rowIdx);
        onMouseEnter === null || onMouseEnter === void 0 ? void 0 : onMouseEnter(event);
    }
    className = clsx(rowClassname, `rdg-row-${rowIdx % 2 === 0 ? 'even' : 'odd'}`, {
        [rowSelectedClassname]: selectedCellIdx === -1
    }, rowClass === null || rowClass === void 0 ? void 0 : rowClass(row), className);
    const cells = [];
    for (let index = 0; index < viewportColumns.length; index++) {
        const column = viewportColumns[index];
        const { idx } = column;
        const colSpan = getColSpan(column, lastFrozenColumnIndex, { type: 'ROW', row });
        if (colSpan !== undefined) {
            index += colSpan - 1;
        }
        const isCellSelected = selectedCellIdx === idx;
        if (isCellSelected && selectedCellEditor) {
            cells.push(selectedCellEditor);
        }
        else {
            cells.push(React.createElement(Cell, { key: column.key, column: column, colSpan: colSpan, row: row, isCopied: copiedCellIdx === idx, isDraggedOver: draggedOverCellIdx === idx, isCellSelected: isCellSelected, dragHandle: isCellSelected ? selectedCellDragHandle : undefined, onRowClick: onRowClick, onRowDoubleClick: onRowDoubleClick, onRowChange: handleRowChange, selectCell: selectCell }));
        }
    }
    return (React.createElement(RowSelectionProvider, { value: isRowSelected },
        React.createElement("div", Object.assign({ role: "row", ref: ref, className: className, onMouseEnter: handleDragEnter, style: getRowStyle(gridRowStart, height) }, props), cells)));
}
const RowComponent = memo(forwardRef(Row));
export default RowComponent;
export function defaultRowRenderer(key, props) {
    return React.createElement(RowComponent, Object.assign({ key: key }, props));
}
