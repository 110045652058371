import tags from '../../constants/tags';
import { api } from '../../service/authApi';
const API_PATH = '/user/settings/';
export const settingApi = api.injectEndpoints({
    endpoints: (build) => ({
        settings: build.query({
            query: (user) => ({
                url: `${API_PATH}`,
                params: { username: user }
            }),
            providesTags: (_Transaction, _err, user) => [{ type: tags.SETTINGS, user }],
        }),
        saveSettings: build.mutation({
            query: (body) => ({
                url: API_PATH,
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: tags.SETTINGS, id: 'LIST' }],
        }),
    }),
});
export const { useSettingsQuery, useSaveSettingsMutation, } = settingApi;
export const { endpoints: { settings }, } = settingApi;
