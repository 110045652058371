import React, { useRef, useContext, useCallback } from "react";
import { Toast } from 'primereact/toast';
const ToastContext = React.createContext({
    showSuccess: () => { },
    showError: () => { }
});
const ToastProvider = ({ children }) => {
    const toast = useRef(null);
    const showError = useCallback((title, msg) => {
        var _a;
        (_a = toast === null || toast === void 0 ? void 0 : toast.current) === null || _a === void 0 ? void 0 : _a.show({ severity: 'error', summary: title, detail: msg, life: 3000 });
    }, [toast]);
    const showSuccess = useCallback((title, msg) => {
        var _a;
        (_a = toast === null || toast === void 0 ? void 0 : toast.current) === null || _a === void 0 ? void 0 : _a.show({ severity: 'success', summary: title, detail: msg, life: 3000 });
    }, [toast]);
    return (React.createElement(ToastContext.Provider, { value: {
            showSuccess,
            showError
        } },
        React.createElement(Toast, { baseZIndex: 5656, ref: toast }),
        children));
};
const useToast = () => {
    const toastHelpers = useContext(ToastContext);
    return toastHelpers;
};
export { ToastContext, useToast };
export default ToastProvider;
