import { format } from "date-fns";
const stripTrailingSlash = (str) => str.charAt(str.length - 1) == "/" ? str.substr(0, str.length - 1) : str;
const urlUtils = (url, queryParams) => {
    // if (process.env.NODE_ENV !== 'development') {
    //     return `${stripTrailingSlash(url)}.json`;
    // }
    return url + (queryParams || '');
};
export const getApiUrl = (base) => {
    // if (process.env.NODE_ENV !== 'development') {
    //     return `${stripTrailingSlash(url)}.json`;
    // }
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        return `https://www.constrogen.com/api/${base}`;
        // return `https://2c4d-115-99-50-137.ngrok-free.app/${base}`;
        // return `http://localhost:8000/${base}`;
    }
    if (location.origin.indexOf('github.io') === -1) {
        return `/api/${base}`;
    }
    return `https://www.constrogen.com/api/${base}`;
};
const formatDate = (value, dFormat) => {
    try {
        return value ? `${format(new Date(value), dFormat || "dd-MM-yyyy")}` : "";
    }
    catch (error) {
        return value;
    }
};
const formatCurrency = (value) => {
    var _a;
    return !!value ? (_a = parseInt(value)) === null || _a === void 0 ? void 0 : _a.toLocaleString(undefined, { style: 'currency', currency: 'INR' }) : "NA";
};
const formatNumber = (value) => {
    var _a;
    return value ? (_a = parseInt(value)) === null || _a === void 0 ? void 0 : _a.toLocaleString(undefined, { maximumFractionDigits: 2 }) : 0;
};
const base64Converter = (file) => new Promise((resolve, reject) => {
    if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var _a;
            let result = (_a = reader.result) === null || _a === void 0 ? void 0 : _a.toString();
            resolve(result);
        };
        reader.onerror = error => {
            reject(error);
        };
    }
    else {
        resolve("");
    }
});
const shouldAllowAdd = (items) => {
    if (items.length === 0)
        return true;
    let temp = items[items.length - 1];
    return (temp === null || temp === void 0 ? void 0 : temp.item_key) &&
        (temp === null || temp === void 0 ? void 0 : temp.itemuom_key);
};
const convertDateValue = (value, reverse) => {
    if (value) {
        return reverse ? `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}` : new Date(value);
    }
    return value;
};
const getMonthsFor = (year) => {
    if (!year) {
        return [];
    }
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // 0-based index for months (0 = January, 11 = December)
    const allMonths = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    if (year === currentYear) {
        return allMonths.slice(0, currentMonth + 1);
    }
    else {
        return allMonths;
    }
};
const getPreviousYears = (value) => {
    if (!value)
        return [];
    const currentYear = new Date().getFullYear();
    const lastYears = [];
    for (let i = 0; i < value; i++) {
        lastYears.push(currentYear - i);
    }
    return lastYears;
};
export { urlUtils, formatDate, formatCurrency, formatNumber, base64Converter, shouldAllowAdd, convertDateValue, getMonthsFor, getPreviousYears };
