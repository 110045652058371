import { api } from '../service';
import { tags } from '../constants';
import { urlUtils } from '../util';
export const shared = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        getManufacturersForItemType: build.query({
            query: (itemType) => {
                return {
                    url: `/inventory/manufacturer/?item_type=${itemType}`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ key }) => ({ type: tags.MANUFACTURERS_FOR_ITEM_TYPE, key })),
                { type: tags.MANUFACTURERS_FOR_ITEM_TYPE, key: 'LIST' },
            ] : [{ type: tags.MANUFACTURERS_FOR_ITEM_TYPE, key: 'LIST' }],
        }),
        getItemSubTypesForItemType: build.query({
            query: (itemType) => {
                return {
                    url: `/inventory/item_subtype/?item_type=${itemType}`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ key }) => ({ type: "ItemSubTypeForItemType", key })),
                { type: "ItemSubTypeForItemType", key: 'LIST' },
            ] : [{ type: "ItemSubTypeForItemType", key: 'LIST' }],
        }),
        getSubmittedBookings: build.query({
            query: () => {
                return {
                    url: urlUtils(`sale/booking/?status=U`),
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ key }) => ({ type: "SubmittedBookings", key })),
                { type: "SubmittedBookings", id: 'LIST' },
            ] : [{ type: "SubmittedBookings", id: 'LIST' }],
        }),
        getAllAPTerm: build.query({
            query: () => {
                return {
                    url: urlUtils('/vendor/ap_term/', `?without_pagination=1`),
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: tags.AP_TERM, id })),
                { type: tags.AP_TERM, id: 'LIST' },
            ],
        }),
        getAllCompanies: build.query({
            query: () => {
                return {
                    url: urlUtils('/client/company/'),
                };
            },
            providesTags: (result = []) => [
                ...result.map(({ key: id }) => ({ type: 'COMPANY', id })),
                { type: 'COMPANY', id: 'LIST' },
            ],
        })
    }),
});
export const { useGetManufacturersForItemTypeQuery, useGetItemSubTypesForItemTypeQuery, useGetSubmittedBookingsQuery, useGetAllAPTermQuery, useGetAllCompaniesQuery } = shared;
