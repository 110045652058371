var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Skeleton } from 'primereact/skeleton';
import './styles.scss';
import { Button } from 'primereact/button';
import { useDownloadExportedDataQuery } from '@igblsln/store';
const Datatable = (_a) => {
    var { children, pagingOptions, showExport = false } = _a, rest = __rest(_a, ["children", "pagingOptions", "showExport"]);
    const [startExport, setStartExport] = useState(false);
    const { data: downloadedData, isError, isSuccess } = useDownloadExportedDataQuery(showExport, { skip: !showExport || !startExport });
    useEffect(() => {
        setStartExport(false);
    }, [isError, isSuccess]);
    const loadingTemplate = (options) => {
        return (React.createElement("div", { className: "flex align-items-center", style: { height: '17px', flexGrow: '1', overflow: 'hidden' } },
            React.createElement(Skeleton, { width: options.cellEven ? (options.field === 'year' ? '30%' : '40%') : '60%', height: "1rem" })));
    };
    return (React.createElement(React.Fragment, null,
        showExport &&
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement(Button, { style: { marginLeft: 'auto' }, label: 'Export', onClick: () => {
                        if (confirm("Are you sure to export?"))
                            setStartExport(true);
                    } })),
        React.createElement(DataTable, Object.assign({ className: "p-datatable-igs", size: "small", scrollable: true, scrollHeight: "flex", resizableColumns: true, columnResizeMode: "fit", virtualScrollerOptions: Object.assign({ itemSize: 40, loadingTemplate }, (pagingOptions || {})), rowHover: true, filterDisplay: "menu", responsiveLayout: "scroll", currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} entries" }, rest), children)));
};
export default Datatable;
