import { retry } from '@reduxjs/toolkit/query/react';
import { api } from '../../service/authApi';
import { tags } from '../../constants';
const API_PATH = '/otp/';
export const authApi = api.injectEndpoints({
    endpoints: (build) => ({
        otpAuth: build.query({
            query: (phoneNum) => ({
                url: `${API_PATH}`,
                params: { phone: phoneNum }
            }),
            providesTags: (_Transaction, _err, user) => [{ type: tags.AUTH_OTP, user }],
        }),
        otpLogin: build.mutation({
            query: (credentials) => ({
                url: `${API_PATH}`,
                method: 'POST',
                body: credentials,
            }),
            extraOptions: {
                backoff: () => {
                    // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
                    retry.fail({ fake: 'error' });
                },
            },
        }),
        login: build.mutation({
            query: (credentials) => ({
                url: 'login',
                method: 'POST',
                body: credentials,
            }),
            extraOptions: {
                backoff: () => {
                    // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
                    retry.fail({ fake: 'error' });
                },
            },
        }),
        register: build.mutation({
            query: (user) => ({
                url: '/user/',
                method: 'POST',
                body: user,
            }),
            extraOptions: {
                backoff: () => {
                    // We intentionally error once on login, and this breaks out of retrying. The next login attempt will succeed.
                    retry.fail({ fake: 'error' });
                },
            },
        })
    }),
});
export const { useLoginMutation, useOtpAuthQuery, useOtpLoginMutation, useRegisterMutation } = authApi;
export const { endpoints: { login }, } = authApi;
