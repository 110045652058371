var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { classNames } from "primereact/utils";
import { Skeleton } from 'primereact/skeleton';
import { confirmDialog } from 'primereact/confirmdialog';
import { Ripple } from 'primereact/ripple';
import Datatable from '../Datatable';
import { Link } from 'react-router-dom';
import { headerIconStyle, newTableDefaultStyle } from '@igblsln/themes';
import { Datacolumn, Datagrid } from '../Dategrid';
import { useToast } from '../Toast';
const buttonStyle = {
    height: 25,
    marginRight: 6,
    fontSize: 'smaller',
    marginBottom: 3
};
const ListLayout = ({ baseRoute = "", description, children, gridProps, showHeader, title, newTable, lazyload, actionColumnWidth, actionColumnInFirst = false, pagination, enableView, viewOnly, disableEdit, onViewClick, topRightElem, customAddBtn = null, customEditOnClick = null, hideAddButton = false, showExport = false, tableLayoutClass = 'new-table-height', hideActionColumn = false, showGridView, allowFilters = false, gridTileRenderer, filterOptions, data, isLoading, navKey, delKey, addBtnLabel, addParams, customBaseRoute, emptyRowMessage, actionBodyTemplate, deleteAction }) => {
    const sessionKey = `${description} Layout`;
    const { showSuccess, showError } = useToast();
    const [layout, setLayout] = useState(sessionStorage.getItem(sessionKey) || 'list');
    const gridPropsWithStyle = Object.assign(Object.assign({}, gridProps), { style: Object.assign(Object.assign({}, newTableDefaultStyle), gridProps === null || gridProps === void 0 ? void 0 : gridProps.style) });
    const deleteData = (data) => __awaiter(void 0, void 0, void 0, function* () {
        if (!deleteAction) {
            return;
        }
        confirmDialog({
            message: 'Are you sure you want to delete?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => __awaiter(void 0, void 0, void 0, function* () {
                var _a;
                try {
                    const resp = yield deleteAction(data);
                    showSuccess('Success', "Deleted Successfully");
                }
                catch (error) {
                    showError("Failed", (_a = error === null || error === void 0 ? void 0 : error.data) === null || _a === void 0 ? void 0 : _a.detail);
                }
            }),
            reject: () => { }
        });
    });
    const actionBodyTemplateWithView = (baseroute, deleteData, className) => {
        return (value) => {
            var _a;
            let url = `${baseroute}/${value[navKey] || value.key}/edit`;
            if (customBaseRoute && customBaseRoute.directUrl) {
                let temp;
                if ((_a = customBaseRoute.args) === null || _a === void 0 ? void 0 : _a.length) {
                    temp = customBaseRoute.directUrl.replace('FIRST_ARG', value[customBaseRoute.args[0]]);
                    url = temp + `${value.key}/edit`;
                }
                if (customBaseRoute.suffix) {
                    url = url + customBaseRoute.suffix;
                }
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(Button, { style: buttonStyle, onClick: () => { onViewClick && onViewClick(value[delKey] || value.key); } }, "View"),
                !disableEdit && !viewOnly &&
                    (customEditOnClick ? React.createElement(Button, { style: buttonStyle, onClick: () => {
                            customEditOnClick(value);
                        } }, "Edit") :
                        React.createElement(Link, { to: url, state: navKey ? value : null, style: { textDecoration: "none" } },
                            React.createElement(Button, { style: buttonStyle }, "Edit"))),
                !viewOnly &&
                    React.createElement(Button, { style: buttonStyle, onClick: () => deleteData(value[delKey] || value.key) }, "Delete")));
        };
    };
    const defaultActionBodyTemplate = (baseroute, deleteData, className) => {
        return (value) => {
            var _a;
            let url = `${baseroute}/${value[navKey] || value.key}/edit`;
            if (customBaseRoute && customBaseRoute.directUrl) {
                let temp;
                if ((_a = customBaseRoute.args) === null || _a === void 0 ? void 0 : _a.length) {
                    temp = customBaseRoute.directUrl.replace('FIRST_ARG', value[customBaseRoute.args[0]]);
                    url = temp + `${value.key}/edit`;
                }
                if (customBaseRoute.suffix) {
                    url = url + customBaseRoute.suffix;
                }
            }
            return (React.createElement(React.Fragment, null,
                customEditOnClick ? React.createElement(Button, { style: buttonStyle, onClick: () => {
                        customEditOnClick(value);
                    } }, "Edit") :
                    React.createElement(Link, { to: url, state: navKey ? value : null, style: { textDecoration: "none" } },
                        React.createElement(Button, { style: buttonStyle }, "Edit")),
                React.createElement(Button, { style: buttonStyle, onClick: () => deleteData(value[delKey] || value.key) }, "Delete")));
        };
    };
    const actionTemplate = actionBodyTemplate ? actionBodyTemplate : (enableView ? actionBodyTemplateWithView(baseRoute, deleteData, '')
        : defaultActionBodyTemplate(baseRoute, deleteData, ''));
    const changeLayout = (l) => {
        sessionStorage.setItem(sessionKey, l);
        setLayout(l);
    };
    const renderLoading = () => (React.createElement("div", { className: "custom-skeleton p-4" },
        React.createElement("div", null,
            React.createElement(Skeleton, { height: "50px", width: "80%", className: "mb-2" }),
            React.createElement(Skeleton, { height: "50px", width: "80%", className: "mb-2" }))));
    const buttonListClass = classNames('p-button p-button-icon-only', { 'p-highlight': layout === 'list' });
    const buttonGridClass = classNames('p-button p-button-icon-only', { 'p-highlight': layout === 'grid' });
    return (React.createElement(React.Fragment, null,
        !newTable && React.createElement(Datatable, Object.assign({ value: data, header: (React.createElement("div", { className: 'flex' },
                React.createElement("h3", { className: classNames('m-0 my-auto') }, description),
                React.createElement(Link, { to: `${baseRoute}/new`, state: addParams, style: { textDecoration: "none" } },
                    React.createElement(Button, { label: `Create ${description}`, className: "ml-3" })))) }, (gridProps || {})),
            React.createElement(Column, { headerStyle: headerIconStyle, style: { maxWidth: enableView ? 200 : 100 }, bodyStyle: { textAlign: 'center', overflow: 'visible' }, body: actionTemplate }),
            children),
        newTable && React.createElement("div", { style: { minHeight: 'inherit', maxHeight: 450 }, className: classNames('w-full', tableLayoutClass) },
            title && React.createElement("div", { className: 'flex', style: { padding: '.5rem' } },
                React.createElement("h3", { className: classNames('m-0 my-auto') }, title)),
            showHeader && React.createElement("div", { className: 'flex', style: { padding: '.5rem', paddingLeft: 0, justifyContent: 'end' } },
                !hideAddButton &&
                    React.createElement(Link, { to: `${baseRoute}/new`, state: addParams, style: { textDecoration: "none" } },
                        React.createElement(Button, { label: addBtnLabel || `Create ${description}`, className: "ml-0" })),
                !!customAddBtn && customAddBtn,
                topRightElem &&
                    React.createElement("div", { className: 'ml-auto' }, topRightElem),
                showGridView && React.createElement("div", { className: 'ml-auto p-selectbutton p-buttonset' },
                    React.createElement("button", { type: "button", className: buttonListClass, onClick: () => changeLayout('list') },
                        React.createElement("i", { className: "pi pi-bars" }),
                        React.createElement(Ripple, null)),
                    React.createElement("button", { type: "button", className: buttonGridClass, onClick: () => changeLayout('grid') },
                        React.createElement("i", { className: "pi pi-th-large" }),
                        React.createElement(Ripple, null)))),
            React.createElement(Datagrid, Object.assign({ gridId: sessionKey, data: data, allowFilters: allowFilters, isDataLoading: isLoading, filterOptions: filterOptions, emptyRowMessage: emptyRowMessage, lazyLoad: lazyload, pagination: pagination, showExport: showExport, gridTileRenderer: gridTileRenderer, showGridView: layout === 'grid' }, (gridPropsWithStyle)),
                !hideActionColumn && actionColumnInFirst &&
                    React.createElement(Datacolumn, { width: actionColumnWidth || (enableView ? (viewOnly ? 60 : 220) : 180), field: "action", header: "", sortable: false, formatter: (props) => actionTemplate(props.row), cellClass: "action-cell-class" }),
                children,
                !hideActionColumn && !actionColumnInFirst &&
                    React.createElement(Datacolumn, { width: actionColumnWidth || (enableView ? (viewOnly ? 60 : 220) : 180), field: "action", header: "", sortable: false, formatter: (props) => actionTemplate(props.row), cellClass: "action-cell-class" })))));
};
export default ListLayout;
