import React from 'react';
import { textEditor } from 'react-data-grid';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { isFunction } from "./Formatter";
import { useToast } from '../../Toast';
const handleInputFocus = (event) => {
    // event.target.click();
    event.target.select();
};
const handleSetRowValue = (row, column, value, onRowChange, onClose) => {
    onRowChange(Object.assign(Object.assign({}, row), { [column]: value }), true);
    onClose(true);
};
const handleInputNumberKeyDown = (row, column, onRowChange, onClose) => (event) => {
    if (event.key === "Tab") {
        const value = parseFloat(event.target.getAttribute('aria-valuenow') || '');
        handleSetRowValue(row, column.key, isNaN(value) ? null : value, onRowChange, onClose);
    }
};
const handleCalenderKeyDown = (row, column, onRowChange, onClose) => (event) => {
    if (event.key === "Tab") {
        handleSetRowValue(row, column.key, event.target.value, onRowChange, onClose);
    }
};
export const handleDropdownKeyDown = (row, column, onRowChange, onClose) => (event) => {
    if (event.key === "Tab") {
    }
};
const handleInputTextKeyDown = (row, column, onRowChange, onClose) => (event) => {
    if (event.key === "Tab") {
        handleSetRowValue(row, column.key, event.target.value, onRowChange, onClose);
    }
};
export function onEditorNavigation({ key, target }) {
    if (key === 'Tab' &&
        (target instanceof HTMLInputElement ||
            target instanceof HTMLTextAreaElement ||
            target instanceof HTMLSelectElement)) {
        return target.matches('.rdg-editor-container > :only-child, .rdg-editor-container > label:only-child > :only-child, .rdg-editor-container > span:only-child > :only-child, .rdg-editor-container > div > div:nth-child(1) > input[type=text] ');
    }
    return false;
}
const getCheckboxEditor = ({ row, column, onRowChange, onClose }) => {
    return React.createElement(Checkbox, { className: "mb-2", defaultChecked: true, checked: row[column.key], onFocus: handleInputFocus, onChange: (e) => {
            row[column.key] = e.checked;
            onRowChange(row, true);
        }, onKeyDown: handleInputTextKeyDown(row, column, onRowChange, onClose) });
};
const getCustomEditor = ({ row, column, onRowChange, onClose }) => {
    const { showError } = useToast();
    if (!!row.key) {
        showError("Restricted", "You can't edit this data now");
        return row.id;
    }
    else {
        return textEditor({ row, column, onRowChange, onClose });
    }
};
const getNumberEditor = ({ row, column, onRowChange, onClose }) => {
    return React.createElement(InputNumber, { autoFocus: true, inputId: "integeronly", className: "p-inputtext-sm", onFocus: handleInputFocus, value: row[column.key], onKeyDown: handleInputNumberKeyDown(row, column, onRowChange, onClose), onValueChange: (e) => { handleSetRowValue(row, column.key, e.value, onRowChange, onClose); }, tabIndex: -1 });
};
const getCurrencyEditor = ({ row, column, onRowChange, onClose }) => {
    return React.createElement(InputNumber, { autoFocus: true, inputId: "currency-india", className: "p-inputtext-sm", onFocus: handleInputFocus, value: row[column.key], onKeyDown: handleInputNumberKeyDown(row, column, onRowChange, onClose), onValueChange: (e) => {
            handleSetRowValue(row, column.key, e.value, onRowChange, onClose);
        }, tabIndex: -1, mode: "currency", currency: "INR", currencyDisplay: "symbol", locale: "en-IN" });
};
const getDateEditor = ({ row, column, onRowChange, onClose }) => {
    return React.createElement(Calendar, { className: "p-inputtext-sm", showIcon: true, dateFormat: "yy-mm-dd", value: row[column.key], 
        // onKeyDown={handleCalenderKeyDown(row, column, onRowChange)}
        onChange: (e) => {
            handleSetRowValue(row, column.key, e.value, onRowChange, onClose);
        }, tabIndex: -1 });
};
const getOptionsEditor = ({ row, column, onRowChange, onClose }) => {
    return React.createElement(Dropdown, { autoFocus: true, className: "p-inputtext-sm editor-dropdown-style", onFocus: handleInputFocus, value: row[column.key], optionLabel: "name", optionValue: "key", onKeyDown: handleDropdownKeyDown(row, column, onRowChange, onClose), onChange: (e) => {
            handleSetRowValue(row, column.key, e.value, onRowChange, onClose);
        }, tabIndex: -1 });
};
export const getEditor = (editorType) => {
    if (!editorType) {
        return null;
    }
    if (isFunction(editorType)) {
        return editorType;
    }
    else if (editorType === 'number') {
        return getNumberEditor;
    }
    else if (editorType === 'currency') {
        return getCurrencyEditor;
    }
    else if (editorType === 'options') {
        return getOptionsEditor;
    }
    else if (editorType === 'date') {
        return getDateEditor;
    }
    else if (editorType === 'checkbox') {
        return getCheckboxEditor;
    }
    else if (editorType === 'custom') {
        return getCustomEditor;
    }
    else {
        return textEditor;
    }
};
