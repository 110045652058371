export const headerStyle = {
    width: '4rem',
    textAlign: 'center'
};
export const headerIconStyle = Object.assign(Object.assign({}, headerStyle), { width: '1em' });
export const headerStyle1 = Object.assign(Object.assign({}, headerStyle), { backgroundColor: 'gray', color: 'white' });
export const headerIconStyle1 = Object.assign(Object.assign({}, headerIconStyle), { backgroundColor: 'gray', color: 'white' });
export const newTableDefaultStyle = {
    '--rdg-header-background-color': '#827f7f',
    'borderRadius': '2px',
    // 'box-shadow' : '0 4px 10px rgba(0,0,0,.3)'
};
