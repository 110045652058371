import clsx from 'clsx';
const cellClassname = `rdg-cell dg-cell`;
const cellFrozenClassname = `rdg-cell-frozen cellFrozen`;
const cellFrozenLastClassname = `rdg-cell-frozen-last cellFrozenLast`;
export function getRowStyle(rowIdx, height) {
    if (height !== undefined) {
        return {
            '--rdg-grid-row-start': rowIdx,
            '--rdg-row-height': `${height}px`
        };
    }
    return { '--rdg-grid-row-start': rowIdx };
}
export function getCellStyle(column, colSpan) {
    return {
        gridColumnStart: column.idx + 1,
        gridColumnEnd: colSpan !== undefined ? `span ${colSpan}` : undefined,
        insetInlineStart: column.frozen ? `var(--rdg-frozen-left-${column.idx})` : undefined
    };
}
export function getCellClassname(column, ...extraClasses) {
    return clsx(cellClassname, {
        [cellFrozenClassname]: column.frozen,
        [cellFrozenLastClassname]: column.isLastFrozenColumn
    }, ...extraClasses);
}
