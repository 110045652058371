import { api } from '../service';
import { tags } from '../constants';
const API_PATH = '/vendor/vendor/';
export const vendorApi = api.injectEndpoints({
    overrideExisting: false,
    endpoints: (build) => ({
        activeVendors: build.query({
            query: () => {
                return {
                    url: `${API_PATH}all/active/vendor`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ACTIVE_VENDOR, id })),
                { type: tags.ACTIVE_VENDOR, id: 'LIST' },
            ] : [{ type: tags.ACTIVE_VENDOR, id: 'LIST' }],
        }),
        activeContractors: build.query({
            query: () => {
                return {
                    url: `/contract/contractors/?without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.ACTIVE_CONTRACTOR, id })),
                { type: tags.ACTIVE_CONTRACTOR, id: 'LIST' },
            ] : [{ type: tags.ACTIVE_CONTRACTOR, id: 'LIST' }],
        }),
        getAllVendorType: build.query({
            query: () => {
                return {
                    url: `/vendor/type/?without_pagination=1`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "ALL_VENDOR_TYPE", id })),
                { type: "ALL_VENDOR_TYPE", id: 'LIST' },
            ] : [{ type: "ALL_VENDOR_TYPE", id: 'LIST' }],
        }),
        getAllContractorType: build.query({
            query: () => {
                return {
                    url: `/contract/type/?without_pagination=1`,
                };
            },
            // transformResponse:(baseQueryReturnValue:any[], meta, arg) => {
            //   baseQueryReturnValue = baseQueryReturnValue.filter(d =>{
            //     return d.contractor === "Y"
            //   })
            //   return baseQueryReturnValue
            // },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: "ALL_CONTARCTOR_TYPE", id })),
                { type: "ALL_CONTARCTOR_TYPE", id: 'LIST' },
            ] : [{ type: "ALL_CONTARCTOR_TYPE", id: 'LIST' }],
        }),
        invoiceForVendor: build.query({
            query: (vendorId) => {
                return {
                    url: `/transaction/vendor/invoice/?vendor=${vendorId}&status=U|P|S`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.INVOICE_FOR_VENDOR, id })),
                { type: tags.INVOICE_FOR_VENDOR, id: 'LIST' },
            ] : [{ type: tags.INVOICE_FOR_VENDOR, id: 'LIST' }],
        }),
        invoiceForContractor: build.query({
            query: (vendorId) => {
                return {
                    url: `/transaction/vendor/invoice/?vendor=${vendorId}&status=U|P`,
                };
            },
            providesTags: (result) => result ? [
                ...result === null || result === void 0 ? void 0 : result.map(({ id }) => ({ type: tags.INVOICE_FOR_CONTRACTOR, id })),
                { type: tags.INVOICE_FOR_CONTRACTOR, id: 'LIST' },
            ] : [{ type: tags.INVOICE_FOR_CONTRACTOR, id: 'LIST' }],
        }),
    }),
});
export const { useActiveVendorsQuery, useActiveContractorsQuery, useGetAllVendorTypeQuery, useGetAllContractorTypeQuery, useInvoiceForVendorQuery, useInvoiceForContractorQuery, } = vendorApi;
