import React from 'react';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
const FilterChip = ({ item, removeItem, onChange }) => {
    var _a;
    const updatefilterValue = (val) => {
        if (onChange && item.data) {
            item.data.tempValue = val;
            onChange(val);
        }
    };
    return (React.createElement(Chip, { template: React.createElement(React.Fragment, null,
            React.createElement("span", { className: "text-sm" },
                item.label,
                ": "),
            React.createElement(InputText, { autoFocus: true, type: item.data.filterType === 'number' ? 'number' : 'text', className: 'p-1 m-1', value: (_a = item.data) === null || _a === void 0 ? void 0 : _a.tempValue, onChange: (e) => updatefilterValue(e.target.value) }),
            React.createElement("span", { tabIndex: 0, onClick: (e) => removeItem(item), className: "p-chip-remove-icon pi pi-times-circle" })), className: "mr-2 mb-2", removable: true }));
};
export default FilterChip;
