import React from 'react';
import { Link, useMatch, useResolvedPath, useLocation } from "react-router-dom";
import { useAuth } from '@igblsln/store';
import { classNames } from "primereact/utils";
const NavLink = ({ to, item, options }) => {
    var _a, _b, _c, _d;
    const auth = useAuth();
    let resolved = useResolvedPath(to);
    let location = useLocation();
    let match = useMatch({ path: resolved.pathname, end: false });
    const accessiblePages = ((_c = (_b = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.role[0]) === null || _b === void 0 ? void 0 : _b.access) === null || _c === void 0 ? void 0 : _c.map((d) => d.name)) || [];
    const shouldDisplay = () => {
        var _a, _b, _c;
        //Temporary fow new changes
        return 'block';
        if (!!(item === null || item === void 0 ? void 0 : item.command)) {
            if ((item === null || item === void 0 ? void 0 : item.label) === "Users") {
                return ((_c = (_b = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.role[0]) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes("admin")) ? 'block' : 'none';
            }
            //As Requested on 21.09.2023 - Change the word Inventory in the main menu to Material Item
            if (item.label == "Material Item") {
                return accessiblePages.includes("Inventory") ? 'block' : 'none';
            }
            if (item.label == "PO") {
                return accessiblePages.includes("Purchases") ? 'block' : 'none';
            }
            if (item.label == "Company") {
                return accessiblePages.includes("Clients") ? 'block' : 'none';
            }
            if (item.label == "Contract Agreement") {
                return 'block';
            }
            return accessiblePages.includes(item === null || item === void 0 ? void 0 : item.label) ? 'block' : 'none';
        }
        else
            return 'block';
    };
    return (React.createElement(Link, { style: { textDecoration: "none", display: shouldDisplay() }, to: to, onClick: item.command, className: classNames(options.className, ((match || ((_d = location.pathname) === null || _d === void 0 ? void 0 : _d.includes(item === null || item === void 0 ? void 0 : item.altUrls))) ? 'p-menuitem-active' : '')), target: item.target },
        React.createElement("span", { className: classNames(options.iconClassName) }),
        React.createElement("span", { className: options.labelClassName }, item.label)));
};
export const templateFn = (to) => (item, options) => (React.createElement(NavLink, { item: item, options: options, to: to }));
export default NavLink;
