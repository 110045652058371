import { Checkbox } from 'primereact/checkbox';
import React from 'react';
const dateFormatter = new Intl.DateTimeFormat(navigator.language);
const currencyFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'inr'
});
const numberFormatter = new Intl.NumberFormat(navigator.language);
export const isFunction = (obj) => {
    return !!(obj && obj.constructor && obj.call && obj.apply);
};
const resolveFieldData = (data, field) => {
    if (data && Object.keys(data).length && field) {
        if (isFunction(field)) {
            return field(data);
        }
        else if (field.indexOf('.') === -1) {
            return data[field];
        }
        else {
            let fields = field.split('.');
            let value = data;
            for (var i = 0, len = fields.length; i < len; ++i) {
                if (value == null) {
                    return null;
                }
                value = value[fields[i]];
            }
            return value;
        }
    }
    else {
        return null;
    }
};
export const defaultValueGetter = (row, field, defaultValue) => {
    return resolveFieldData(row, field) || defaultValue;
};
export const displayValueGetter = (column, row, field, defaultValue) => {
    if (column && (column.displayValueGetter || column.displayField)) {
        return column.displayValueGetter ? column.displayValueGetter(row, field, defaultValue) :
            defaultValueGetter(row, column.displayField || field, defaultValue);
    }
    return defaultValueGetter(row, field, defaultValue);
};
export function TimestampFormatter({ column, row, field, defaultValue }) {
    let displayValue = displayValueGetter(column, row, field, defaultValue);
    return React.createElement(React.Fragment, null, displayValue ? dateFormatter.format(displayValue) : '');
}
export function CurrencyFormatter({ value }) {
    return React.createElement(React.Fragment, null, value ? currencyFormatter.format(value) : "₹0");
}
export function DisplayCurrencyFormatter({ column, row, field, defaultValue }) {
    return React.createElement(CurrencyFormatter, { value: displayValueGetter(column, row, field, defaultValue) });
}
export function NumberFormatter({ column, row, field, defaultValue }) {
    return React.createElement(React.Fragment, null, numberFormatter.format(displayValueGetter(column, row, field, defaultValue)));
}
export function DefaultFormatter({ column, row, field, defaultValue }) {
    return React.createElement(React.Fragment, null, displayValueGetter(column, row, field, defaultValue));
}
export function CheckboxFormatter({ value }) {
    return React.createElement(Checkbox, { checked: value, style: { width: '100%', display: 'flex', margin: '10px auto', justifyContent: 'center' } });
}
export const getFormatter = (type, field, valueGetter, defaultValue) => {
    switch (type) {
        case 'number':
            return (props) => props.row[field] && React.createElement(NumberFormatter, { column: props.column, row: props.row, field: field, defaultValue: defaultValue });
        case 'currency':
            return (props) => React.createElement(DisplayCurrencyFormatter, { column: props.column, row: props.row, field: field, defaultValue: defaultValue });
        case 'date':
            return (props) => React.createElement(TimestampFormatter, { column: props.column, row: props.row, field: field, defaultValue: defaultValue });
        case 'checkbox':
            return (props) => React.createElement(CheckboxFormatter, { value: valueGetter(props.row, field, defaultValue) });
        default:
            return (props) => React.createElement(DefaultFormatter, { column: props.column, row: props.row, field: field, defaultValue: defaultValue });
    }
};
