import React from 'react';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';
const NoMatch = ({ relative }) => {
    return (React.createElement("div", { className: classNames('h-full w-full', relative && 'relative') },
        React.createElement("div", { className: classNames("absolute top-50 left-50 text-center", relative && 'relative'), style: { transform: 'translate(-50%,-50%)' } },
            React.createElement(Card, { className: classNames(relative && 'center-content'), title: "Page Not Found", style: { width: '25rem', marginBottom: '2em' } },
                React.createElement("img", { src: "./assets/img/under-construction.png", alt: "", className: "comming-soon-image" })))));
};
export default NoMatch;
